let apibaseURL;
let baseURL;
const apiVersion = '1';
const localhostUrl = {
  oauth: 'http://localhost:8081',
  storemanager: 'http://localhost:8094',
  settings: 'http://localhost:8083',
  sync: 'http://localhost:8088',
  gateway: 'http://localhost:8099',
  orders: 'http://localhost:8100',
  webhooks: 'http://localhost:8101',
};
const platformHostname = process.env.platform_hostname;

if (platformHostname && platformHostname != "localhost") {
  baseURL = `${platformHostname}/api/v/${apiVersion}`;
  apibaseURL = baseURL.substring(0, 4).toLowerCase() === 'http' ? baseURL : `https://${baseURL}`;
} else {
  apibaseURL = baseURL = localhostUrl.storemanager;
}

export const apiConfigHelper = {
  isLocal: function () {
    return apibaseURL == localhostUrl.storemanager;
  }, // EoF - isLocal()

  getBaseUrl: function () {
    return baseURL;
  }, // EoF - getBaseUrl()

  getApiBaseUrl: function () {
    return apibaseURL;
  }, // EoF - getApiBaseUrl()

  getBaseUrlForOauth: function () {
    var self = this;
    return self.isLocal() ? localhostUrl.oauth : self.getApiBaseUrl();
  }, // EoF - getBaseUrlForOauth()

  getBaseUrlForSettings: function () {
    var self = this;
    return self.isLocal() ? localhostUrl.settings : self.getApiBaseUrl();
  },

  getBaseUrlForSync: function (store) {
    var self = this;
    if (self.isLocal()) {
      return localhostUrl.sync;
    } else if (store){
      return store.dataCentre.endpoint;
    } else {
      return self.getApiBaseUrl();
    }
  },

  getBaseUrlForWebhooks: function () {
    var self = this;
    return self.isLocal() ? localhostUrl.webhooks : self.getApiBaseUrl();
  },

  getBaseUrlForOrders: function () {
    var self = this;
    return self.isLocal() ? localhostUrl.orders : self.getApiBaseUrl();
  },

  getBaseUrlForGateway: function () {
    var self = this;
    return self.isLocal() ? localhostUrl.gateway : self.getApiBaseUrl();
  },

  getStoreUrlForGateway: function () {
    return this.getStoreUrl(this.getBaseUrlForGateway());
  },

  getStoreUrl: function (currentBaseUrl) {
    if (this.isLocal()) {
      return currentBaseUrl;
    }
    let storeHost = sessionStorage.getItem('storePrimaryDomain');
    let baseHost = this.getHostname(currentBaseUrl);
    if (storeHost && baseHost !== storeHost) {
      return currentBaseUrl.replace(baseHost, storeHost);
    }
    return currentBaseUrl;
  },

  /**
   * Get hostname from URL string
   * @param url
   * @returns {string}
   */
  getHostname: function (url) {
    return url.replace('https://', '').replace('http://', '').split(/[/?#]/)[0];
  },
};
